import { NpmInstitutionalPage } from '@plurix/ecom-pages'
import React from 'react'
import { DownloadIcon } from 'src/assets/DownloadIcon'
import useCmsInstitutional from 'src/hooks/useCmsInstitutional'

import pdf from '../../../static/institucional/politica_de_privacidade.pdf'
import { storeUrl } from '../../../store.config'

import '../../styles/pages/privacidade.scss'

const Privacy = () => {
  const { privacyBanners } = useCmsInstitutional()

  return (
    <NpmInstitutionalPage
      title="Política de Privacidade"
      banners={privacyBanners}
    >
      <div className="privacy-container">
        <p>
          <strong>SUPERMERCADO SUPERPÃO Ltda.</strong>, com sede no Estado do
          Paraná/PR, na Cidade de Guarapuava, na Rua Guaíra, nº 55555,
          Boqueirão, CEP: 85020-000, inscrito no CNPJ/MF sob o nº
          77883320/0001-61, (“Lojista”), empresa que disponibiliza o Programa de
          Descontos, Cliente Superpão (“Programa”), aos clientes que realizem
          compras em seus estabelecimentos (“Usuário” ou “Usuários”), vem, por
          intermédio desta Política de Privacidade (“Política”), apresentar aos
          seus Usuários as informações essenciais sobre a forma que os seus
          dados serão utilizados e tratados.
        </p>
        <p>
          <b>
            A coleta, o uso e o compartilhamento dos dados pessoais dos Usuários
            do Lojista ficarão sujeitos a esta Política de Privacidade e suas
            atualizações. Ao acessar o site{' '}
            <a href={storeUrl} target="_blank" rel="noreferrer">
              {storeUrl.replace('https://', '')}
            </a>{' '}
            ou Aplicativo, o Usuário deverá ler a presente Política,
            manifestando sua concordância com relação às suas disposições, por
            meio do botão disponibilizado para tanto. Por meio de tal ato, o
            Usuário demonstra sua manifestação livre, expressa, informada e
            inequívoca, sem quaisquer reservas, acerca de todo o conteúdo deste
            documento, nos termos da Lei Geral de Proteção de Dados (Lei
            nº13.709/2018).
          </b>
        </p>
        <p>
          Para entrar em contato com o Lojista, basta enviar um e-mail para:
          <br />
          <strong>
            <a href="mailto: marketing@superpao.com.br">
              marketing@superpao.com.br
            </a>
          </strong>
        </p>
        <p>
          <a
            href={pdf}
            download="politica_de_privacidade.pdf"
            type="application/pdf"
          >
            <DownloadIcon />
            Baixar Documento Completo
          </a>
        </p>
      </div>
    </NpmInstitutionalPage>
  )
}

export default Privacy
